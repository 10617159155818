import { useMemo } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const Particle = () => {

    const init = initParticlesEngine(async (engine) => {
        await loadSlim(engine);
    })

    const options = useMemo(
        () => ({
            background: {
                color: {
                    value: "#5034C4",
                },
            },
            fullScreen: { enable: true },
            fpsLimit: 60,
            interactivity: {
                events: {
                    onHover: {
                        enable: true,
                        mode: "grab",
                    },
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                },
                modes: {
                    grab: {
                        distance: 140,
                        line_linked: {
                          opacity: 1
                        }
                      },
                      bubble: {
                        distance: 400,
                        size: 40,
                        duration: 2,
                        opacity: 8,
                        speed: 1
                      },
                      repulse: {
                        distance: 200,
                        duration: 0.4
                      },
                      push: {
                        particles_nb: 4
                      },
                      remove: {
                        particles_nb: 2
                      }
                },
            },
            particles: {
                color: {
                    value: "#000000",
                },
                links: {
                    color: "#000000",
                    distance: 150,
                    enable: true,
                    opacity: 0.4,
                    width: 1,
                },
                move: {
                    enable: true,
                    direction: "none",
                    outModes: {
                        default: "out",
                    },
                    random: false,
                    straight: false,
                    speed: 0.2,
                    bounce: false,
                    attract: {
                        enable: false,
                        rotateX: 600,
                        rotateY: 1200,
                    },

                },
                number: {
                    value: 120,
                },
                opacity: {
                    value: 0.5,
                    random: false,
                    animation: {
                        enable: true,
                        speed: 1,
                        minimumValue: 0.1,
                        sync: false,
                    },
                },
                shape: {
                    type: "circle",
                    stroke: {
                        color: "#000000",
                        width: 0,
                    },
                    polygon: {
                        sides: 5,
                    },
                },
                size: {
                    value: 3,
                    random: true,
                    animation: {
                        enable: false,
                        speed: 1,
                        minimumValue: 0.1,
                        sync: false,
                    },
                },
            },
            detectRetina: true,
        }),
        [],
    );

    return (
        <Particles
            init={init}
            id="tsparticles"
            options={options}
        />
    );

};

export default Particle;
import './assets/css/App.css';
import Home from './pages/Home'
import ParticlesBg from './components/ParticlesBg';
import { NextUIProvider } from "@nextui-org/react";

const App = () => {
    return (
        <NextUIProvider>
            <div className="App">
                <div className='absolute -z-1'>
                    <ParticlesBg />
                </div>
                <Home />
            </div>
        </NextUIProvider>
    );
}

export default App;

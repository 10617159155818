import { useRef, useState } from 'react';
import axios from 'axios';

const SignUpSuccessMessage = () => {
    return (
        <div className="mx-2 flex items-center p-4 mb-4 mt-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <div>
                Fasten your seatbelt! 🚀
            </div>
        </div>
    )
}

const SignUpErrorMessage = () => {
    return (
        <div className="mx-2 flex items-center p-4 mb-4 mt-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div>
                <span className="font-medium">Error!</span> There was an error in the sign-up process, please try again later.
            </div>
        </div>
    )
}

const InvalidEmailMessage = () => {
    return (
        <div className="mx-2 flex items-center p-4 mb-4 mt-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div>
                <span className="font-medium">Error!</span> Please enter a valid email address.
            </div>
        </div>
    )
}

const Newsletter = () => {
    const form = useRef();
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    const [signUpError, setSignUpError] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        const emailInput = form.current.querySelector('input[name="sender"]');
        const email = emailInput.value;
        
        if (!validateEmail(email)) {
            setInvalidEmail(true);
            setSignUpError(false);
            return;
        }
        
        const data = {
            email: email,
        }
        axios.post('https://fs50g1bxl5.execute-api.us-west-1.amazonaws.com/v1', data)
        .then(res=>{
            setSignUpSuccess(true)
        })
        .catch(error =>{
            console.log(error)
            setSignUpError(true)
        })
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    return (
        <section className='flex flex-col items-center'>
            {!signUpSuccess && (
                <form ref={form} className="flex mx-2 bg-white rounded-full flex-row items-center" onSubmit={sendEmail}>
                    <input 
                        type="text" 
                        name="sender" 
                        className="flex-1 h-12 p-4 text-gray-900 focus:ring-0 shadow-none rounded-l-full border-white focus:border-white text-base" 
                        placeholder="Enter your email" 
                        aria-label="Enter your email"
                    />
                    <input 
                        type="submit" 
                        className="h-12 py-2 rounded-r-full px-5 text-white bg-purple-600 hover:bg-purple-700 focus:ring-0 shadow cursor-pointer"
                        value="Join the waitlist"
                    />
                </form>
            
            )}

            {signUpSuccess && <SignUpSuccessMessage />}
            {signUpError && <SignUpErrorMessage />}
            {invalidEmail && <InvalidEmailMessage />}
        </section>
    )
}

export default Newsletter;
import Socials from "./Socials";

export default function Footer() {
    return (
        <footer className="bg-gray-800 py-6">
            <div className="flex flex-col items-center">
                <Socials />
                {/* Contact Us */}
                <div className="text-sm text-center mt-4 text-purple-100">
                    For business-related inquiries, contact us <a href="mailto:contact@scrapegraphai.com" className="hover:underline">here</a>.
                </div>
                {/* Copyrights note */}
                <div className="text-sm mt-2 text-center text-purple-100">
                    &copy; scrapegraphai.com. All rights reserved.
                </div>
            </div>
        </footer>
    );
}

import Hero from '../components/Hero'

const Home = () => {
    return (
        <div className="Home">
            <Hero />
        </div>
    );
}

export default Home;